<template>

    <div> 
      
        
        <div class="overlay-setting " :class="showPhoneWindow ? 'show':''" @click="showHidePhone"></div>
        <div class="app-settings" :class="showPhoneWindow ? 'active':''">
            <a href="javascript:;" id="settingbutton" class="setting" :style="uaRegistrationStatus=='unregistered' ? 'background: #ec1313': `background: #009b31;`" @click="showHidePhone" >
                <h5 class="mb-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-call" data-v-5522efca="">
                        <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                    </svg>
                </h5>
            </a>
            
            <div class="ps sidbarchat p-3">
                
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 align-self-center">
                        <div v-if="uaRegistrationStatus=='unregistered'">
                            <span class="badge badge-danger">{{$t("phone.status.not_connected")}}</span>
                        </div>
                        <div v-if="uaRegistrationStatus!='unregistered'">
                            <span v-if="uaMainStatus=='idle'" class="badge outline-badge-success me-1">{{$t("phone.status.idle")}}</span>
                            <span v-if="uaMainStatus=='trying'" class="badge outline-badge-warning me-1">{{$t("phone.status.trying")}}</span>
                            <span v-if="uaMainStatus=='ringing'" class="badge outline-badge-secondary me-1">{{$t("phone.status.ringing")}}</span>
                            <span v-if="uaMainStatus=='talking'" class="badge outline-badge-danger me-1">{{$t("phone.status.talking")}}</span>
                        </div>
                    </div>
                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 order-sm-0 order-1 text-end">
                        <h4>{{firstName}} {{lastName}}</h4>
                    </div>
                </div>
                
                <div class="text-end" v-html="extension"/>
                <hr>
                <div v-if="callType=='outgoing'">
                    
                    <div class="alert  alert-light-danger alert-dismissible mb-4" role="alert" v-if= "negativeResponse !=''" >
                        <strong>{{ negativeResponse}}</strong> 
                    </div>
                    
                    <label v-if="callDirection=='pstn'">External Number:</label>
                    
                    <div class="input-group">
                        <div class="select2" style="width:80%" v-if="callDirection=='pstn'">
                            <div class="input-group pb-4">
                                <input type="text" v-model="pstnDestinationNumber" class="form-control" placeholder="Recipient's number" aria-label="Recipient's username" :disabled="!(uaMainStatus=='idle') ? 'disabled' : false" />
                            </div>
                        </div>
                        <button class="btn btn-outline-dark" v-if="callDirection=='pstn'" @click="togleDirection()" :disabled="!(uaMainStatus=='idle') ? 'disabled' : false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-repeat" data-v-5522efca=""><polyline points="17 1 21 5 17 9"></polyline><path d="M3 11V9a4 4 0 0 1 4-4h14"></path><polyline points="7 23 3 19 7 15"></polyline><path d="M21 13v2a4 4 0 0 1-4 4H3"></path></svg>
                        </button>
                    </div>

                    <label v-if="callDirection=='pstn' && cc_select_options.length>1">Place call from Callcenter:</label>

                    <div  v-if="callDirection=='pstn' && cc_select_options.length>1" class="input-group pb-4">
                        <div class="select2" style="width:100%"  >
                            <multiselect 
                                v-model="callcenter_used" 
                                :options="cc_select_options" 
                                select-first=true
                                placeholder="" selected-label="" select-label="" :allow-empty="false" :can-deselect="false"
                                deselect-label="" object="false" track-by="value" label="name" 
                                class="multiselect-blue"
                                :disabled="!(uaMainStatus=='idle') ? 'disabled' : false"
                                >
                            </multiselect>
                        </div>
                    </div>

                    
                    <label v-if="callDirection=='local'"> Internal Extension:</label>
                    
                    <div  class="input-group">
                        <div class="select2" style="width:80%" v-if="callDirection=='local'" >
                            <multiselect 
                                v-model="localDestinationNumber" 
                                :options="internalPhonebook" 
                                placeholder="" selected-label="" select-label="" :allow-empty="false" :can-deselect="false"
                                deselect-label="" object="false" track-by="number" label="name" 
                                class="multiselect-blue"
                                :disabled="!(uaMainStatus=='idle') ? 'disabled' : false"
                                >
                            </multiselect>
                        </div>
                        <button class="btn btn-outline-dark" v-if="callDirection=='local'" @click="togleDirection()" :disabled="!(uaMainStatus=='idle') ? 'disabled' : false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-repeat" data-v-5522efca=""><polyline points="17 1 21 5 17 9"></polyline><path d="M3 11V9a4 4 0 0 1 4-4h14"></path><polyline points="7 23 3 19 7 15"></polyline><path d="M21 13v2a4 4 0 0 1-4 4H3"></path></svg>
                        </button>
                    </div>

                </div>
                <div v-if="callType=='incoming'" >
                    <div class="text-center">
                        Incoming Call from <br>
                        <h4>{{displayName}} </h4>
                        <h5>({{callerId }})</h5>
                    </div>
                </div>

                <div class="btn-group-toggle row mb-4 mx-0  mt-5 ms-4" >
                        <!-- Answer Incoming calls button button  | Apears when call is ringing and direction is inbound-->
                        <div v-if="callType=='incoming' && uaMainStatus=='ringing'" class="text-color radio-primary col-6 ps-0 mb-3 custom-control custom-radio text-center" :class="(callType=='incoming' && uaMainStatus=='ringing')? 'col-6' : 'col-12'">
                            <button type="button" class="btn btn-success btn-lg mb-2 me-2 rounded-circle"  :class="{ 'flashing-button': isFlashing, 'color-change': isColorChanging }" :disabled="false" @click="answer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-incoming" data-v-5522efca=""><polyline points="16 2 16 8 22 8"></polyline><line x1="23" y1="1" x2="16" y2="8"></line><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                            </button>
                        </div>
                        
                        <!--Bye/Cancel button-->
                        <div v-if="uaMainStatus!='idle'" class="text-color radio-primary col-6 ps-0 mb-3 custom-control custom-radio text-center" :class="(callType=='incoming' && uaMainStatus=='ringing')? 'col-6' : 'col-12'">
                            <button type="button" class="btn btn-danger btn-lg mb-2 me-2 rounded-circle" @click="hangup" :disabled="xferHold? 'disabled': false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-off" data-v-5522efca=""><path d="M10.68 13.31a16 16 0 0 0 3.41 2.6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7 2 2 0 0 1 1.72 2v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.42 19.42 0 0 1-3.33-2.67m-2.67-3.34a19.79 19.79 0 0 1-3.07-8.63A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91"></path><line x1="23" y1="1" x2="1" y2="23"></line></svg>
                            </button>
                        </div>
                        
                        <!--Outbound Call-->
                        <div v-if="uaMainStatus=='idle'" class="text-color radio-primary col-12 ps-0 mb-3 custom-control custom-radio text-center" >
                            <button type="button" class="btn btn-success btn-lg mb-2 me-2 rounded-circle" @click="makeOutboundCall">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-outgoing" data-v-5522efca=""><polyline points="23 7 23 1 17 1"></polyline><line x1="16" y1="8" x2="23" y2="1"></line><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                            </button>
                        </div>
                    </div>

                <div v-if="uaMainStatus=='talking' && xferHold==false && confHold==false && inConference==false && callDirection=='pstn' && callType=='outgoing'">
                    <div class="btn-group mt-4 ms-3 mb-1 text-center me-3" role="group" aria-label="Second group" style= "display: flex;justify-content: center;align-items: center;">
                            <button type="button" class="btn btn-outline-primary me-1" @click="sendDTMF(1)">
                                &nbsp1
                            </button>
                            <button  type="button" class="btn btn-outline-primary me-1" @click="sendDTMF(2)">
                                &nbsp2
                            </button>
                            <button type="button" class="btn btn-outline-primary" @click="sendDTMF(3)">
                                &nbsp3
                            </button>
                    </div>
                    <div class="btn-group ms-3 mb-1 text-center me-3" role="group" aria-label="Second group" style= "display: flex;justify-content: center;align-items: center;">
                            <button type="button" class="btn btn-outline-primary me-1" @click="sendDTMF(4)">
                                &nbsp4
                            </button>
                            <button  type="button" class="btn btn-outline-primary me-1" @click="sendDTMF(5)">
                                &nbsp5
                            </button>
                            <button type="button" class="btn btn-outline-primary" @click="sendDTMF(6)">
                                &nbsp6
                            </button>
                    </div>
                    <div class="btn-group ms-3 mb-1 text-center me-3" role="group" aria-label="Second group" style= "display: flex;justify-content: center;align-items: center;">
                            <button type="button" class="btn btn-outline-primary me-1" @click="sendDTMF(7)">
                                &nbsp7
                            </button>
                            <button  type="button" class="btn btn-outline-primary me-1" @click="sendDTMF(8)">
                                &nbsp8
                            </button>
                            <button type="button" class="btn btn-outline-primary" @click="sendDTMF(9)">
                                &nbsp9
                            </button>
                    </div>
                    <div class="btn-group ms-3 mb-2 text-center me-3" role="group" aria-label="Second group" style= "display: flex;justify-content: center;align-items: center;">
                            <button type="button" class="btn btn-outline-primary me-1" @click="sendDTMF('*')">
                                &#xFE61;
                            </button>
                            <button  type="button" class="btn btn-outline-primary me-1" @click="sendDTMF(0)">
                                &nbsp0
                            </button>
                            <button type="button" class="btn btn-outline-primary" @click="sendDTMF('#')">
                                &#xFE5F;
                            </button>
                    </div>
                </div>
                <div v-if="uaMainStatus=='talking' &&  inConference==false">

                    <div v-if="xferHold==false && confHold==false" class="btn-group ms-3 mb-2 text-center me-3" role="group" aria-label="First group" style= "display: flex;justify-content: center;align-items: center;">
                        <button v-if='onHold==false' type="button" class="btn btn-secondary me-1" @click="hold" :disabled="xfer_mode==true ? 'disabled': false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-pause" data-v-5522efca=""><rect x="6" y="4" width="4" height="16"></rect><rect x="14" y="4" width="4" height="16"></rect></svg>
                            <div class="text-center">
                            &nbspHold
                            </div>
                        </button>
                        <button v-if='onHold==true' type="button" class="btn btn-warning me-1" @click="unhold" :disabled="xfer_mode==true?'disabled':false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-pause" data-v-5522efca="">
                                <rect x="6" y="4" width="4" height="16"></rect>
                                <rect x="14" y="4" width="4" height="16"></rect>
                                <line x1="6" y1="4" x2="18" y2="20" stroke="currentColor" stroke-width="2" stroke-linecap="round"></line>
                            </svg>
                            &nbspUnhold
                        </button>
                        <button type="button" class="btn btn-info me-1" @click="startXFER" :disabled="onHold==true?'disabled':false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-forwarded" data-v-5522efca=""><polyline points="19 1 23 5 19 9"></polyline><line x1="15" y1="5" x2="23" y2="5"></line><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                            &nbspxfer
                        </button>
                        <!-- <button type="button" class="btn btn-primary" @click="startCONF" :disabled="onHold==true?'disabled':false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users" data-v-5522efca=""><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                            &nbspconf
                        </button> -->
                    </div>

                    <div v-if="xferHold==true">
                        <div class=mb-3>
                            <!-- <span v-if="xferStatus=='idle'" class="badge outline-badge-success me-1">{{$t("phone.status.idle")}}</span> -->
                            <span v-if="xferStatus=='trying'" class="badge outline-badge-warning me-1">{{$t("phone.status.trying")}}</span>
                            <span v-if="xferStatus=='ringing'" class="badge outline-badge-secondary me-1">{{$t("phone.status.ringing")}}</span>
                            <span v-if="xferStatus=='talking'" class="badge outline-badge-danger me-1">{{$t("phone.status.talking")}}</span>
                        </div>
                        <div class="alert  alert-light-danger alert-dismissible mb-4" role="alert" v-if= "negativeResponseXfer !=''" >
                            <strong>{{ negativeResponseXfer}}</strong> 
                        </div>

                        <div class="input-group">
                            <div><label> <h4>Transfer Call To: </h4></label></div>
                            <div class="select2" style="width:80%">
                                <multiselect 
                                    v-model="xferDestination" 
                                    :options="xferPhonebook" 
                                    placeholder="" selected-label="" select-label="" :allow-empty="false" :can-deselect="false"
                                    deselect-label="" object="false" track-by="number" label="name" 
                                    class="multiselect-blue"
                                    :disabled="xferStatus!='idle'"
                                    
                                    >
                                </multiselect>
                            </div>
                            <button class="btn btn-outline-success" @click="callXferer" :disabled = "(xferDestination && xferStatus=='idle') ? false : true">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-forwarded" data-v-5522efca=""><polyline points="19 1 23 5 19 9"></polyline><line x1="15" y1="5" x2="23" y2="5"></line><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                            </button>
                        </div>
                        <div  class="btn-group mt-3 mb-2 text-center" role="group" aria-label="First group" style= "display: flex;justify-content: center;align-items: center;">
                            <button   @click="cancelXFER" type="button" class="btn btn-danger me-1" :disabled="xfer_mode==true?'disabled':false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-rotate-ccw" data-v-5522efca=""><polyline points="1 4 1 10 7 10"></polyline><path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"></path></svg>
                                &nbspCancel
                            </button>
                            <button type="button" class="btn btn-info" @click="transferNow" :disabled="xferStatus!='talking' ? 'disabled': false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-forwarded" data-v-5522efca=""><polyline points="19 1 23 5 19 9"></polyline><line x1="15" y1="5" x2="23" y2="5"></line><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                <div class="text-center">
                                &nbspTransfer
                                </div>
                            </button>
                        </div>
                    </div>

                    <div v-if="confHold==true">
                        {{conferenceStatus}}
                        <div class="alert  alert-light-danger alert-dismissible mb-4" role="alert" v-if= "negativeResponseConf !=''" >
                                    <strong>{{ negativeResponseConf}}</strong> 
                                </div>
                        <div class="input-group ms-3">
                            <div><label> <h4>Conference with: </h4></label></div>
                            <div class="select2" style="width:70%">
                                <multiselect 
                                    v-model="confDestination" 
                                    :options="xferPhonebook" 
                                    placeholder="" selected-label="" select-label="" :allow-empty="false" :can-deselect="false"
                                    deselect-label="" object="false" track-by="number" label="name" 
                                    class="multiselect-blue"
                                    
                                    >
                                </multiselect>
                            </div>
                            <button class="btn btn-outline-success" @click="callConferer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-forwarded" data-v-5522efca=""><polyline points="19 1 23 5 19 9"></polyline><line x1="15" y1="5" x2="23" y2="5"></line><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                            </button>
                        </div>
                        <div  class="btn-group ms-3 mt-3 mb-2 text-center me-3" role="group" aria-label="First group" style= "display: flex;justify-content: center;align-items: center;">
                            <button   @click="cancelCONF" type="button" class="btn btn-danger me-1" :disabled="confernceBridgeEstablished ?  false : true" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-rotate-ccw" data-v-5522efca=""><polyline points="1 4 1 10 7 10"></polyline><path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"></path></svg>
                                &nbspCancel
                            </button>
                            <button type="button" class="btn btn-info me-1" @click="confernceNow" :disabled="confernceBridgeEstablished ?  false : true">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-forwarded" data-v-5522efca=""><polyline points="19 1 23 5 19 9"></polyline><line x1="15" y1="5" x2="23" y2="5"></line><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                <div class="text-center">
                                &nbspConference
                                </div>
                            </button>
                        </div>
                    </div>



                    <!-- <div class="text-end me-3" v-if="xferHold==true">
                        <button @click="cancelXFER" type="button" class="btn btn-default toggle-code-snippet"><span>Cancel and Return</span></button>
                    </div> -->

                    <!-- <button v-if="readyForTransfer" @click="transferNow">Trasnfer Now </button> -->
                </div>

                <!-- <div class="btn-group-toggle row mt-5 mb-4 mx-0">
                    <div class="text-color radio-primary col-12 ps-0 mb-3 custom-control custom-radio">
                        <input type="radio" id="collapsible" class="custom-control-input" value="collapsible-vertical">
                        <label class="custom-control-label" for="collapsible">Log in to Call Center</label>
                    </div>
                </div>
                 -->

<!--                 
                <button type="button" @click="startXFER">Transfer</button>
                <button type="button" @click="dialXFER">Dial</button>
                <button type="button" @click="startConfernce">conference</button> -->
                <!-- <button type="button" @click="sendDTMFseries('*31001#')">DTMF</button> -->
            </div>
        </div>
    </div>
   
    <audio id="localAudio" ref="localAudio" muted="muted" autoplay></audio>
    <audio id="remoteAudio" ref="remoteAudio" autoplay></audio>

    <audio id="localAudioXfer" ref="localAudioXfer" autoplay  muted="muted"></audio>
    <audio id="remoteAudioXfer" ref="remoteAudioXfer"  autoplay></audio>
    
    <!-- <audio id="localAudio" muted="muted" autoplay></audio>
    <audio id="remoteAudio" autoplay></audio> -->
    
</template>  


<script setup>
import SIP from 'sip.js'; // Version 0.11.6
const { currentRoute } = useRouter();
const {$get, $swal} = useNuxtApp();

// Constants
const serverPort = 9381;

// Style components
import Multiselect from '@suadelabs/vue3-multiselect';
import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css';
import '~/assets/css/select2.css';

// Media files
import ringbackFile from '/ringback.mp3'; 
import ringtoneFile from '/gio_resotone.mp3'; 
import waitingFile from '/waiting.mp3'; 

const callToEavsedrop = useEavsedropDispatch();

watch(() => callToEavsedrop.value, async (newValue) => {
    if ( Object.keys(newValue).length>0 ) {
        // alert("Interceoting...");
        console.log("Intercept UUID: ", newValue.sip_account.intercept_uuid);
        console.log("Socket: ", newValue.sip_account.socket);
        eavsedropCall(newValue.sip_account.intercept_uuid, newValue.sip_account.socket, newValue.sip_account.calltype )
    }
    
}, { deep: true })

const cc = ref([]); //useCallcenters();

const callcenter_used = ref("");

function requestNotificationPermission() {
  if (Notification.permission === 'default') {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
      }
    });
  }
}

if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
    // const currentRoute = router.currentRoute;
    console.log("--------", currentRoute.value.fullPath);
  navigator.serviceWorker.register('/sw-cc.js')
    .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
        // navigator.serviceWorker.oncontrollerchange = function() {
            if (registration.active) {
                
            registration.active.postMessage({ type: 'INIT', payload: currentRoute.value.fullPath });
            console.log('[Main Thread] Message sent to Service Worker');
            
        } else {
            alert("NOT ACTIVE!!!")
        }
        // };
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });

  navigator.serviceWorker.addEventListener('message', (event) => {
    const data = event.data;
    if (data && data.action) {
      if (data.action === 'accept') {
        acceptCall();
      } else if (data.action === 'reject') {
        rejectCall();
      }
    }
  });
}

function acceptCall() {
  // Your logic to handle accept call
  console.log('Call accepted');
  answer();
}

function rejectCall() {
  // Your logic to handle reject call
  console.log('Call rejected');
  hangup();
}

window.addEventListener('focus', function() {
  console.log('Tab is focused');
  // Perform session-related tasks here
});




// Settings
let registrationRetryTimeout = 10 * 1000; // 10 seconds
let timer;

// Switches
const showPhoneWindow = ref(false); // Shows if phone window is open or closed

// Status variables
const uaRegistrationStatus = ref("unregistered"); // registered or unregistered
const uaMainStatus = ref("idle"); // idle, trying, ringing, talking
const xferStatus = ref("idle"); // idle, trying, ringing, talking
const conferenceStatus = ref("idle"); //idle, trying, ringing, talking
const callType = ref("outgoing"); // outgoing, incoming
const callDirection = ref("pstn"); // pstn, local
const negativeResponse = ref("");
const negativeResponseXfer = ref("");
const negativeResponseConf = ref("");


const pstnDestinationNumber = ref("");
const localDestinationNumber = ref("");

// Local variables
let uaMain; // used for primary calls;
let uaSecondary; // used for xfer, conference or callwaiting
let session; // current session
let attendedTransferSession;
const localAudioRef = ref(null);
const remoteAudioRef = ref(null);
const localAudioXferRef = ref(null);
const remoteAudioXferRef = ref(null);



const onHold = ref(false);
const readyForTransfer = ref(false);
const readyForConfernce = ref(false)
const confernceBridgeEstablished = ref(true)

const cc_select_options = ref([]);


let ringback, ringtone, waitingback;

// Interface data
const firstName = ref(""); // Here we keep user's personal data like firstname, lastname
const lastName = ref("");
// const myExtensions = ref([]); // Here we keep extensions of the user (may be more than one)
const extension = ref("");
const internalPhonebook = ref([]); // useInternalPhonebook();
const xferPhonebook = ref([]);
const callerId = ref();
const xferHold = ref(false);
const xferDestination = ref("");
const confHold = ref(false);
const confDestination = ref("");
const inConference = ref(false);

let myAgentId; // Agent id 
let voipToken; // jwt token
let domain; // jwt token

const serviceWorkerRegistration = ref(null);

onMounted(async () => {
    localAudioRef.value = document.getElementById('localAudio');
    remoteAudioRef.value = document.getElementById('remoteAudio');
    localAudioXferRef.value = document.getElementById('localAudioXfer');
    remoteAudioXferRef.value = document.getElementById('remoteAudioXfer');
    
        
    ringtone = new Audio(ringtoneFile);
    ringback = new Audio(ringbackFile);
    waitingback = new Audio(waitingFile);
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
        navigator.serviceWorker.register('/sw-cc.js')
        .then((registration) => {
            console.log('Service Worker registered with scope:', registration.scope);
            serviceWorkerRegistration.value = registration;
        })
        .catch((error) => {
            console.error('Service Worker registration failed:', error);
        });

        navigator.serviceWorker.addEventListener('message', (event) => {
            const data = event.data;
            if (data && data.action) {
                if (data.action === 'accept') {
                    acceptCall();
                } else if (data.action === 'reject') {
                    rejectCall();
                }
            }
        });
    }
});

function showNotification(title, options) {
  if (serviceWorkerRegistration.value) {
    serviceWorkerRegistration.value.showNotification(title, options)
      .catch(error => {
        console.error('Error showing notification:', error);
      });
  } else {
    console.error('Service Worker registration not available');
  }
}

function notifyUser(from) {
    let uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);
  const options = {
    body: `New Call from ${from}.`,
    icon: '/favicon.ico',
    tag: uniqueId,
    actions: [
      { action: 'accept', title: 'Accept' },
      { action: 'reject', title: 'Reject' }
    ]
  };
  showNotification(`Incoming Call from ${from}`, options);
}



const initPhone = async () => {
    // The following request gives all credentials for SIP App
    // alert(`wss://${domain}:${serverPort}`);
    const configuration = {
        iceServers: [], // Empty iceServers list to avoid using STUN/TURN servers
        iceCandidatePoolSize: 0,
        iceTransportPolicy: 'relay'
    };

    const config = {
        uri: `sip:${myAgentId}@${domain}`,
        sessionDescriptionHandlerFactoryOptions: {
            peerConnectionOptions: {
                rtcConfiguration: {
                    iceServers: [] // Empty array to disable ICE servers
                }
            }
        },
        transportOptions: {
            wsServers: [`wss://${domain}:${serverPort}`],
            // keepAliveInterval:10,
            reconnectionTimeout: 4,
            maxReconnectionAttempts: 20000,
            transportRecoveryAttempts:10,
            transportRecoveryTimer:1,

        },
        media: {
            codecs: [
                { name: 'opus', clockRate: 48000, channels: 2 },
                { name: 'pcmu', clockRate: 8000 },
                { name: 'pcma', clockRate: 8000 }
            ],
            constraints: {
                audio: true,    // Enable audio
                video: false    // Disable video
            },
        },
        
        iceTransportPolicy: 'none', // Or set to 'relay' to force using TURN only
        iceCandidatePoolSize: 0,
        iceTransportPolicy: 'relay',
        rtcConfiguration: configuration,
        register: true,
        traceSip: false,
        debug:false
        
    };

    uaMain = new SIP.UA(config);
    uaMain.start();

    uaMain.transport.on("connected", () => {
        // if (timer) {
        //     clearRetryTimer();
        // }
    });
    uaMain.transport.on("disconnected", () => {
        // alert("disconnected");
        // uaMain.stop();
        // if (!timer) {
        //     retryRegistration();
        // }
    });
    uaMain.transport.on('wsClose', (event) => {
        console.log('WebSocket closed:', event);
        // Handle WebSocket close event
    });

    uaMain.transport.on('wsError', (event) => {
        console.log('WebSocket error:', event);
        // Handle WebSocket error event
    });

    uaMain.register({
        extraHeaders: [`JWT: ${voipToken}`]
    });

    uaMain.on('registered', () => {
        uaRegistrationStatus.value = "registered";
    });
    uaMain.on('unregistered', () => {
        uaRegistrationStatus.value = "unregistered";
    });
    uaMain.on('registrationFailed', (response, cause) => {
        uaRegistrationStatus.value = "unregistered";
    });

    uaMain.on('invite', handleIncomingCall);

    uaMain.on('message', function(data, data2){
        let body = removeTrailingCRLF(data.request.body);
        let arr = body.split(";");
        let event = {};
        for (let item of arr){
            let k = item.split("=");
            event[k[0]] = k[1];
        }
        
        let reason = event.reason
        switch (event.state) {
            case "xfer-bridge-success":
                if (reason == "SUCCESS"){
                    if (conferenceStatus.value != "idle") {
                        conferenceStatus.value = "talking";
                        confernceBridgeEstablished.value = true;
                    }


                 
               }
            break;
            case "xfer-bridge-ended":
                // conferenceStatus.value = "";
                confernceBridgeEstablished.value = false;
            break;
        }


        // if (/xfer-bridge-success/.test(data.request.body)){
        //     readyForConfernce.value = true;
        // }
        // // confernceBridgeEstablished
        // if (/xfer-bridge-ended;reason=(?!NORMAL_CLEARING).*/.test(data.request.body)){
        //     confHold.value = false;
        //     readyForConfernce.value = false;
        //     return
        // }

        // if (/xfer-bridge-ended/.test(data.request.body)){
        //     console.log(data.request.body, "Not Matches")
        //     if (readyForConfernce.value == true) {
        //         inConference.value = true;
        //         readyForConfernce.value = false;
        //         confHold.value = false;
        //     }
        // }
    })
};

const eavsedropCall = (intercept_uuid, vsocket, calltype) => {
    let to = "eavsdrop";
    const target = `sip:${to}@${domain}`;
    const options = {
        media: {
            constraints: { audio: true, video: false },
            render: {
                local: { audio: localAudioRef.value },
                remote: { audio: remoteAudioRef.value}
            }
        },
        extraHeaders: [`JWT: ${voipToken}`, `P-UUID: ${intercept_uuid}`, `P-Socket: ${vsocket}`, `P-Calltype: ${calltype}` ]
    };
    uaMainStatus.value = "trying";
    let s = uaMain.invite(target, options);
    //setupSessionEvents(s);
    handleSession(s);

}

const makeOutboundCall = () => {
    let to = localDestinationNumber.value.number;
    if (callDirection.value === "pstn") to = pstnDestinationNumber.value;
    if (callDirection.value === "local") prepareXferPhonebook(to);
    const target = `sip:${to}@${domain}`;

    const options = {
        media: {
            constraints: { audio: true, video: false },
            render: {
                local: { audio: localAudioRef.value},
                remote: { audio: remoteAudioRef.value }
            }
        },
        extraHeaders: [`JWT: ${voipToken}`, `P-CCID: ${callcenter_used.value.value}`]
    };
    uaMainStatus.value = "trying";
    waitingback.play();
    waitingback.currentTime = 0;
    waitingback.loop = true;

    let s = uaMain.invite(target, options);
    //setupSessionEvents(s);
    handleSession(s);
};

const cleanupMedia = () => {
    const localAudio = localAudioRef.value;
    if (localAudio && localAudio.srcObject) {
        const tracks = localAudio.srcObject.getTracks();
        tracks.forEach(track => track.stop());
        localAudio.srcObject = null;
    }

    // Stop and release remote audio
    const remoteAudio = remoteAudioRef.value;
    if (remoteAudio && remoteAudio.srcObject) {
        const tracks = remoteAudio.srcObject.getTracks();
        tracks.forEach(track => track.stop());
        remoteAudio.srcObject = null;
    }
};


const handleSession = (s) => {
    session = s;
    
    s.on('trackAdded', () => {
        const pc = s.sessionDescriptionHandler.peerConnection;
        const localStream = pc.getLocalStreams()[0];
        localAudioRef.value.srcObject = localStream;
        const remoteStream = pc.getRemoteStreams()[0];
        remoteAudioRef.value.srcObject = remoteStream;
    });

    s.on('accepted', () => {
        uaMainStatus.value = "talking";
        ringback.pause();
        ringtone.pause();
    });

    s.on('directionChanged', function() {
        let direction = s.sessionDescriptionHandler.getDirection();
        if (direction === 'sendrecv') {
            onHold.value = false;
        } else {
            onHold.value = true;
        }
    });

    s.on('progress', (response) => {
        waitingback.pause()
        if (response.status_code === 100) {
            uaMainStatus.value = "trying";
        }
        if (response.status_code === 183 && response.body) {
            uaMainStatus.value = "ringing";
            const sdh = s.sessionDescriptionHandler;
            sdh.setDescription(response.body).then(() => {
                // Attach the remote media stream to the audio element
                const streams = sdh.peerConnection.getRemoteStreams();
            });
        }
        if (!response.body && (response.status_code === 183 || response.status_code === 180)) {
            ringback.play();
            uaMainStatus.value = "ringing";
            ringback.currentTime = 0;
            ringback.loop = true;
        }
    });

    s.on('terminated', () => {
        callType.value = 'outgoing';
        uaMainStatus.value = "idle";
        ringtone.pause();
        cleanupMedia();
        onHold.value = false;
        xferHold.value = false;
        confHold.value = false;
        inConference.value = false;
        readyForTransfer.value = false;
    });

    s.on('rejected', (response, cause) => {
        negativeResponse.value = `${cause}`;
        setTimeout(() => {
            negativeResponse.value = "";
        }, 4000);
        ringback.pause();
        uaMainStatus.value = "idle";
        cleanupMedia();
    });
    
    s.on('bye', () => {
        ringback.pause();
        cleanupMedia();
        uaMainStatus.value = "idle";
    });
}


const handleIncomingCall = (s) => {
    session = s;
    callType.value = 'incoming';
    uaMainStatus.value = "ringing";
    showPhoneWindow.value = true;
    ringtone.play();
    ringtone.currentTime = 0;
    ringtone.loop = true;
    prepareXferPhonebook("");
    
    
    
    callerId.value = session.remoteIdentity.displayName || session.remoteIdentity.uri.user;
    notifyUser( callerId.value );
    handleSession(s);

}

// Clean up media

const answer = () => {
    ringtone.pause();
    session.accept();
};

const hangup = () => {
    waitingback.pause();
    session.terminate();
};

const sendDTMF = (digit) => {
    session.dtmf(digit);
};

const hold = () => {
    session.hold();
};

const unhold = () => {
    session.unhold();
};

const getMyData = async () => {
    const response = await $get('/api/protected/my/data');
    const {data} = response;
    firstName.value = data.firstName;
    lastName.value = data.lastName;
    myAgentId = data.agentId;
    voipToken = data.token;
    domain = data.domain;
    extension.value = `<span class="badge badge-primary me-1">${data.extension}</span>`;

    
    let current_sip_account_id = data.agentId;
    let unique = [];


    const response2 = await $get('/api/protected/agent/account');
    for (let item of response2.data) {
        let object = {};
        object.name = `${item.firstname} ${item.lastname}`;
        object.number = item.extension;
        if (item.sip_account_id != current_sip_account_id){
            if (!(unique.includes(item.sip_account_id))){
                unique.push(item.sip_account_id)
                internalPhonebook.value.push(object);
            }
        }
    }
    const response3 = await $get(`/api/protected/agent/participation/${current_sip_account_id}`);
    const arrayOfCallcenterSipAccountIsParticipated = response3.data;
    
    cc.value = (await $get(`/api/protected/callcenter`)).data;
    console.log("========>>>", cc.value)
    for (let item of cc.value) {
        
        if (arrayOfCallcenterSipAccountIsParticipated.includes(item._id)){
            
            cc_select_options.value.push({name: item.name, value:item._id})
            if (callcenter_used.value == "") 
                callcenter_used.value = {name: item.name, value:item._id};
        }
    }
};

const prepareXferPhonebook = (excluded_extension) => {
    xferPhonebook.value = [];
    for (let item of internalPhonebook.value){
        if (item.number != excluded_extension ){
            xferPhonebook.value.push(item);
        }
    }
};

const startXFER = () => {
    xferHold.value = true;
    session.hold();
};

const cancelXFER = async () => {
    xferHold.value = false;
    session.unhold();
    attendedTransferSession.terminate();
};

const sleep = async(n) => {
    return new Promise((resolve)=>{
        setTimeout(()=>{
            resolve()
        }, n*1000);
    })
}

const startCONF = async () => {
    confHold.value = true;
    conferenceStatus.value = "trying";
    // session.hold();
}

const cancelCONF = async () => {
    // confHold.value = false;
    await sendDTMF("#"); 
    conferenceStatus.value = "canceled";
    // session.unhold();
};


const startCONF2 = async () => {
    confHold.value = true;
    if (callType.value == "outgoing")
        await sendDTMFseries("*3"); 

    if (callType.value == "incoming")
        await sendDTMFseries("*4"); 
};

const cancelCONF2 = async () => {
    // confHold.value = false;
    await sendDTMF("#"); 
    // session.unhold();
};

const callConferer = async() => {
    session.unhold(); 
    if (callType.value == "outgoing")
        await sendDTMFseries("*3"); 

    if (callType.value == "incoming")
        await sendDTMFseries("*4"); 

    await sleep(2);
    let ext = confDestination.value.number+"AA";
    await sendDTMFseries(ext);
}

const sendDTMFseries = async (str) => {
    for (let i = 0; i < str.length; i++) {
        let char = str[i];
        sendDTMF(char);
        await sleep(0.2);
    }
}


const cleanupMediaXfer = () => {
    const localAudioXfer = localAudioXferRef.value;
    if (localAudioXfer && localAudioXfer.srcObject) {
        const tracks = localAudioXfer.srcObject.getTracks();
        tracks.forEach(track => track.stop());
        localAudioXfer.srcObject = null;
    }

    // Stop and release remote audio
    const remoteAudioXfer = remoteAudioXferRef.value;
    if (remoteAudioXfer && remoteAudioXfer.srcObject) {
        const tracks = remoteAudioXfer.srcObject.getTracks();
        tracks.forEach(track => track.stop());
        remoteAudioXfer.srcObject = null;
    }
    // closeAllMediaStreams();
    
};

// const closeAllMediaStreams = () => {
//     // Close local audio streams
//     closeMediaStream(localAudioRef);
//     closeMediaStream(localAudioXferRef);

//     // Close remote audio streams
//     closeMediaStream(remoteAudioRef);
//     closeMediaStream(remoteAudioXferRef);
// };

const closeMediaStream = (audioRef) => {
    const audioElement = audioRef.value;
    if (audioElement && audioElement.srcObject) {
        const tracks = audioElement.srcObject.getTracks();
        tracks.forEach(track => track.stop());
        audioElement.srcObject = null;
    }
};

const callXferer = () => {
    const target = `sip:${xferDestination.value.number}@${domain}`;
    xferStatus.value = "trying";
    const options = {
        media: {
            constraints: { audio: true, video: false },
            render: {
                local: { audio: localAudioXferRef.value },
                remote: { audio: remoteAudioXferRef.value }
            }
        },
        extraHeaders: [`JWT: ${voipToken}`]
    };

    attendedTransferSession = uaMain.invite(target, options);

    if (attendedTransferSession) {
        
        attendedTransferSession.on('progress', (response) => {
        
            if (response.status_code === 100) {
                xferStatus.value = "trying";
            }

            if (response.status_code === 183 && response.body) {
                xferStatus.value = "ringing";
                const sdh = attendedTransferSession.sessionDescriptionHandler;
                sdh.setDescription(response.body).then(() => {
                    // Attach the remote media stream to the audio element
                    const streams = sdh.peerConnection.getRemoteStreams();
                });
            }

            if (!response.body && (response.status_code === 183 |  response.status_code === 180) ) {
                xferStatus.value = "ringing";
                ringback.play();
                ringback.currentTime = 0;
                ringback.loop = true;

            };
        });


        attendedTransferSession.on('trackAdded', () => {
            const pc = attendedTransferSession.sessionDescriptionHandler.peerConnection;
            if (localAudioXferRef.value) {
                const localStream = pc.getLocalStreams()[0];
                localAudioXferRef.value.srcObject = localStream;
            }
            
            if (remoteAudioXferRef.value) {

                const remoteStream = pc.getRemoteStreams()[0];
                remoteAudioXferRef.value.srcObject = remoteStream;
            }
        });

        attendedTransferSession.on('accepted', () => {
            readyForTransfer.value = true;
            xferStatus.value = "talking";
            ringback.pause();
        });

        attendedTransferSession.on('rejected', (response, cause) => {
            negativeResponseXfer.value = `${cause}`;
            setTimeout(() => {
                negativeResponseXfer.value = "";
            }, 4000);
            ringback.pause();
            cleanupMediaXfer();
            ringback.pause;
        });


        attendedTransferSession.on('terminated', () => {
            cleanupMediaXfer(); // Cleanup only if transfer didn't happen
            xferStatus.value = "idle";
            ringback.pause;
        });
        attendedTransferSession.on('bye', () => {
            cleanupMediaXfer();
        });

        attendedTransferSession.on('refer', () => {
            cleanupMediaXfer();
        });
    }
};


const transferNow = () => {
    if (session && attendedTransferSession) {
        // unhold();
        session.refer(attendedTransferSession);
        // cleanupMediaXfer()
        // cleanupMedia();
        // session.once('referAccepted', () => {
        //     attendedTransferSession.terminate();
        // });
    }
};

const confernceNow = () => {
    sendDTMF(0);
    console.log(`Hit 0`)
}

if (session) {
    session.on('terminated', () => {
        // if (!readyForTransfer.value) {
            cleanupMedia(); // Cleanup only if transfer didn't happen
        // }
    });
}


getMyData().then(() => {
    initPhone();
});

// Toggle, whether to show or hide right sidebar
const showHidePhone = () => {
    showPhoneWindow.value = !showPhoneWindow.value;
};

const togleDirection = () => {
    callDirection.value = callDirection.value === 'pstn' ? 'local' : 'pstn';
};

function removeTrailingCRLF(input) {
    return input.replace(/[\r\n]+$/, '');
}

</script>

<style >
.multiselect__tags {
    height: 54px;
    padding: 16px 40px 0 8px;
    /* border-radius: 0px; */
    /* border: 0px; */
}

.form-control {
    letter-spacing: 1px;
    padding: 0.00rem 0.5rem;
}
.btn {
    height: 54px;
}

.multiselect--disabled .multiselect__current, .multiselect--disabled .multiselect__select {
    background: #ededed;
    color: #a6a6a6;
    height: 53px;
}

.btn.rounded-circle {
    height: 65px;
    width: 65px;
    padding: 8px 8px;
}

.flashing-button {
  animation: pulse 0.3s infinite alternate; /* 1s duration, infinite loop, alternate direction */
}

.color-change {
  background-color: green; /* Initial color */
  transition: background-color 0.3s ease; /* Smooth transition */
}

@keyframes pulse {
  from {
    opacity: 1; /* Start with full opacity */
  }
  to {
    opacity: 0.7; /* Fade to half opacity */
  }
}

</style>